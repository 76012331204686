import { useEffect, useState } from 'react';
import { request } from 'graphql-request';
import { sendTransactions } from '@multiversx/sdk-dapp/services/transactions/sendTransactions';
import { refreshAccount } from '@multiversx/sdk-dapp/utils/account/refreshAccount';
import {
    useGetAccount,
    useGetActiveTransactionsStatus,
    useGetNetworkConfig
} from 'hooks';
import { MyApiNetworkProvider } from 'helpers/MyApiNetworkProvider';
import { graphqlUrl } from 'config';
import { Query, gqlNftStaking } from 'types';
import Decimal from 'decimal.js';
import { NftStakingPoolComponent } from 'components/NftStakingPoolComponent';

export const NftStaking = () => {
    const {
        network: { apiAddress }
    } = useGetNetworkConfig();
    const apiNetworkProvider = new MyApiNetworkProvider(apiAddress);

    const { address } = useGetAccount();
    const { success, fail } = useGetActiveTransactionsStatus();

    const [nftStakingPools, setNftStakingPools] = useState<
        gqlNftStaking[] | undefined
    >();
    const [tokenPrice, setTokenPrice] = useState<Decimal | undefined>();
    const [errorLoading, setErrorLoading] = useState<boolean>(false);

    const fetchData = () => {
        request<Query>(
            graphqlUrl,
            `
            query($user: String) {
                ev {
                  nftStakingPools {
                    _address
                    stakingToken
                    stakingTokens
                    rewardTokens
                    rewardsForUser(address:$user) {
                        tokenId
                        amount
                    }
                    userStaking(user:$user) {
                        token
                        nonce
                    }
                    userUnstaking(user:$user) {
                        token
                        nonce
                        unstake_deadline
                    }
                    unstakingPeriod
                    isBoosted
                    currentUserBoost(user:$user) {
                        boost_deadline
                        card_type
                    }
                  }
                }
                tokenPrice
              }
            `,
            {
                user: address
            }
        )
            .then(({ ev }) => {
                if (!ev) return;
                if (!ev.nftStakingPools) return;
                setNftStakingPools(ev.nftStakingPools);
                if (!ev.tokenPrice) return;
                setTokenPrice(new Decimal(ev.tokenPrice));
            })
            .catch((err: any) => {
                console.error(err);
                setErrorLoading(true);
            });
    };

    useEffect(() => {
        fetchData();
    }, [success, fail]);

    if (nftStakingPools === undefined) {
        return (
            <div className='container mt-5'>
                <div>
                    <h1 className='text-center mb-5 text-custom ev-text'>
                        NFT Staking
                    </h1>
                    <h1 className='text-center'>Loading...</h1>
                </div>
            </div>
        );
    }

    if (errorLoading) {
        return (
            <div className='container mt-5'>
                <div>
                    <h1 className='text-center mb-5 text-custom ev-text'>
                        NFT Staking
                    </h1>
                    <h1 className='text-center'>
                        Error loading data, please try again later
                    </h1>
                </div>
            </div>
        );
    }

    return (
        <div className='container my-auto'>
            <div className='container mt-5'>
                <h1 className='text-center mb-5 text-custom ev-text'>
                    NFT Staking
                </h1>

                <div className='d-flex align-center justify-content-center'>
                    <button
                        className='claim-button btn btn-secondary btn-custom w-75 px-4 mb-4'
                        style={{
                            lineHeight: '1.2'
                        }}
                        disabled={
                            nftStakingPools.filter(
                                (pool) =>
                                    pool.rewardsForUser?.filter(
                                        (reward) =>
                                            reward.amount?.toString() !== '0'
                                    ).length !== 0
                            ).length === 0
                        }
                        onClick={async () => {
                            const transactions: any[] = [];
                            await refreshAccount();

                            nftStakingPools.forEach((pool) => {
                                if (
                                    pool.rewardsForUser?.filter(
                                        (reward) =>
                                            reward.amount?.toString() !== '0'
                                    ).length === 0
                                ) {
                                    return;
                                }
                                transactions.push({
                                    value: 0,
                                    data: 'claimRewards',
                                    receiver: pool._address,
                                    gasLimit: 25_000_000
                                });
                            });

                            const { sessionId } = await sendTransactions({
                                transactions,
                                transactionsDisplayInfo: {
                                    processingMessage: 'Claiming rewards...',
                                    errorMessage:
                                        'An error has occured during claiming',
                                    successMessage:
                                        'Rewards claimed successfully!'
                                }
                            });
                        }}
                    >
                        Claim all Rewards
                    </button>
                </div>
                {nftStakingPools.map((pool) => (
                    <NftStakingPoolComponent
                        pool={pool}
                        tokenPrice={tokenPrice}
                    />
                ))}
            </div>
        </div>
    );
};
