import { useEffect, useState } from 'react';
import { request } from 'graphql-request';
import {
    useGetAccount,
    useGetActiveTransactionsStatus,
    useGetNetworkConfig
} from 'hooks';
import { MyApiNetworkProvider } from 'helpers/MyApiNetworkProvider';
import { graphqlUrl } from 'config';
import { Query, gqlNftStaking } from 'types';
import { NftStakingPoolComponent } from 'components/NftStakingPoolComponent';
import SwiperEV from 'components/swiperCyborgs';
import Decimal from 'decimal.js';

export const InfinityStaking = () => {
    const {
        network: { apiAddress }
    } = useGetNetworkConfig();
    const apiNetworkProvider = new MyApiNetworkProvider(apiAddress);

    const { address } = useGetAccount();
    /*
	const address =
		"erd1fs9ka9ftnx6plf7qen87a7fw5aqjy7cvsgh8ccnglfm9hqjsf8cqe88zrx";
    */

    const { success, fail } = useGetActiveTransactionsStatus();

    const [nftStakingPools, setNftStakingPools] = useState<
        gqlNftStaking[] | undefined
    >();
    const [tokenPrice, setTokenPrice] = useState<Decimal | undefined>();
    const [errorLoading, setErrorLoading] = useState<boolean>(false);
    const [nStakedCybors, setNStakedCybors] = useState<number | undefined>();

    const fetchData = () => {
        request<Query>(
            graphqlUrl,
            `
            query($user: String) {
                ev {
                  nftStakingPools {
                    _address
                    stakingToken
                    stakingTokens
                    rewardTokens
                    rewardsForUser(address:$user) {
                        tokenId
                        amount
                    }
                    userStaking(user:$user) {
                        token
                        nonce
                    }
                    userUnstaking(user:$user) {
                        token
                        nonce
                        unstake_deadline
                    }
                    unstakingPeriod
                    nStakedPositions
                    isBoosted
                    currentUserBoost(user:$user) {
                        boost_deadline
                        card_type
                    }
                  }
                  tokenPrice
                }
              }
            `,
            {
                user: address
            }
        )
            .then(({ ev }) => {
                if (!ev) return;
                if (!ev.nftStakingPools) return;
                setNftStakingPools(
                    ev.nftStakingPools.filter(
                        (p) =>
                            p._address ===
                                'erd1qqqqqqqqqqqqqpgqd7f32re7pj0xqha8zru8kgwsqepvhn8kdn3qxej29v' ||
                            p._address ===
                                'erd1qqqqqqqqqqqqqpgq0g3jdnd2jlw2nh0zxs4ghedr3qmwnntkdn3qha9myl'
                    )
                );

                let nStakedCybors =
                    ev.nftStakingPools.filter(
                        (p) =>
                            p._address ===
                            'erd1qqqqqqqqqqqqqpgqd7f32re7pj0xqha8zru8kgwsqepvhn8kdn3qxej29v'
                    )[0].nStakedPositions ?? 0;
                nStakedCybors +=
                    ev.nftStakingPools.filter(
                        (p) =>
                            p._address ===
                            'erd1qqqqqqqqqqqqqpgq0g3jdnd2jlw2nh0zxs4ghedr3qmwnntkdn3qha9myl'
                    )[0].nStakedPositions ?? 0;
                nStakedCybors +=
                    (ev.nftStakingPools.filter(
                        (p) =>
                            p._address ===
                            'erd1qqqqqqqqqqqqqpgqe98h78xksheffcy2n3nwmdj5hfsmlvkxdn3qlttq96'
                    )[0].nStakedPositions ?? 0) / 3;
                setNStakedCybors(nStakedCybors);

                if (!ev.tokenPrice) return;
                setTokenPrice(new Decimal(ev.tokenPrice));
            })
            .catch((err: any) => {
                console.error(err);
                setErrorLoading(true);
            });
    };

    useEffect(() => {
        fetchData();
    }, [success, fail]);

    if (nftStakingPools === undefined) {
        return (
            <div className='container mt-5'>
                <div>
                    <h1 className='text-left text-custom ev-text'>
                        E.V Cyborgs
                    </h1>
                    <h1 className='text-center'>Loading...</h1>
                </div>
            </div>
        );
    }

    if (errorLoading) {
        return (
            <div className='container mt-5'>
                <div>
                    <h1 className='text-left text-custom ev-text'>
                        E.V Cyborgs
                    </h1>
                    <h1 className='text-center'>
                        Error loading data, please try again later
                    </h1>
                </div>
            </div>
        );
    }

    return (
        <div className='container-fluid w-100 d-flex flex-column flex-lg-row my-5'>
            <div className='dashboard-left w-100 col-lg-9'>
                <h1 className='text-left text-custom ev-text'>E.V Cyborgs</h1>
                <div className='staking-cards-container d-flex flex-row flex-wrap gap-5'>
                    {nftStakingPools.map((pool) => (
                        <NftStakingPoolComponent
                            key={pool._address}
                            pool={pool}
                            tokenPrice={tokenPrice}
                        />
                    ))}
                </div>
            </div>
            <div className='dashboard-right col-12 col-lg-3'>
                <div className='dashboard-right-container d-flex flex-column'>
                    <SwiperEV />
                    <div className='dashboard-right-info'>
                        <div className='p-3 text-center'>
                            <h3 className='font-weight-bold pb-3'>
                                E.V Cyborgs
                            </h3>
                            <div className='card-general-data card card-dashboard text-center relative overflow-visble'>
                                <div className='card-body'>
                                    <h4>Total supply: 3000</h4>
                                    {nStakedCybors && (
                                        <h4>Staked: {nStakedCybors}</h4>
                                    )}
                                    <h4>Royalties: 10%</h4>
                                </div>
                                <p className='text-danger'>
                                    Collection is sold out
                                </p>
                                <button
                                    className='btn btn-outline-primary btn-md active'
                                    onClick={() =>
                                        window.open(
                                            'https://flipix.io/collection/EVCYB-aea8b4',
                                            '_blank'
                                        )
                                    }
                                >
                                    🛒 Trade now on FLIPiX
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
