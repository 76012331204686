import { useEffect, useState, useMemo } from 'react';
import { NonFungibleToken, gqlNftStaking } from 'types';
import { SectionSelector } from 'components/SectionSelector';
import { PageState } from 'components';
import { BuyOnMarketplace } from './buyOnMarketplace';
import { faGrip } from '@fortawesome/free-solid-svg-icons';
import {
    useGetAccount,
    useGetActiveTransactionsStatus,
    useGetNetworkConfig
} from 'hooks';
import { lkutxIdentifier as lkunityxIdentifier, utxIdentifier } from 'config';
import { sendTransactions } from '@multiversx/sdk-dapp/services/transactions/sendTransactions';
import { refreshAccount } from '@multiversx/sdk-dapp/utils/account/refreshAccount';
import { NftVisualizer } from 'components/NftVisualizer';
import { MyApiNetworkProvider } from 'helpers/MyApiNetworkProvider';
import {
    TokenPayment,
    TransferTransactionsFactory,
    GasEstimator,
    Address
} from '@multiversx/sdk-core';
import { FormatAmount } from '@multiversx/sdk-dapp/UI';
import { string2hex, timestampToDate } from 'helpers';
import BigNumber from 'bignumber.js';
import MultiversxSymbol from 'assets/img/multiversx-symbol.svg';
import UtxLogo from 'assets/img/utx.png';
import LkunityxLogo from 'assets/img/lkunityx.png';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import Decimal from 'decimal.js';
import 'swiper/css/pagination';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

enum Section {
    staked = 'Staked',
    unstaked = 'Unstaked',
    wallet = 'Wallet'
}

export const NftStakingPoolComponent = ({
    pool: {
        _address,
        stakingToken,
        stakingTokens,
        rewardTokens,
        rewardsForUser,
        userStaking,
        userUnstaking,
        unstakingPeriod,
        isBoosted,
        currentUserBoost
    },
    tokenPrice
}: {
    pool: gqlNftStaking;
    tokenPrice: Decimal | undefined;
}) => {
    const tickerCombo = (stakingTokens ?? [stakingToken!]).join('&');

    if (
        _address === undefined ||
        stakingToken === undefined ||
        stakingTokens === undefined ||
        rewardTokens === undefined ||
        rewardsForUser === undefined ||
        userStaking === undefined ||
        userUnstaking === undefined ||
        unstakingPeriod === undefined ||
        isBoosted === undefined ||
        currentUserBoost === undefined
    ) {
        return null;
    }

    const { address } = useGetAccount();

    const {
        network: { apiAddress },
        chainID
    } = useGetNetworkConfig();
    const apiNetworkProvider = new MyApiNetworkProvider(apiAddress);
    const { success, fail } = useGetActiveTransactionsStatus();

    const [expanded, setExpanded] = useState<boolean>(false);

    const [section, setSection] = useState<Section>(Section.staked);
    const [stakedNfts, setStakedNfts] = useState<NonFungibleToken[]>([]);
    const [unstakedNfts, setUnstakedNfts] = useState<NonFungibleToken[]>([]);
    const [walletNfts, setWalletNfts] = useState<NonFungibleToken[]>([]);
    const [inputBoostCard, setInputBoostCard] = useState<string>('');

    const stakedNftsCheckedCountEqualPerCollection = useMemo(() => {
        const countCheckedPerCollection = stakedNfts.reduce(
            (acc, nft) => {
                const collection = nft.collection;
                if (acc[collection] === undefined) {
                    acc[collection] = 0;
                }
                if (nft._checked) {
                    acc[collection] += 1;
                }
                return acc;
            },
            {} as Record<string, number>
        );
        const c = Object.values(countCheckedPerCollection);
        for (let i = 0; i < c.length; i++) {
            if (c[i] !== c[0]) {
                return false;
            }
        }
        return true;
    }, [stakedNfts]);
    const walletNftsCheckedCountEqualPerCollection = useMemo(() => {
        const countCheckedPerCollection = walletNfts.reduce(
            (acc, nft) => {
                const collection = nft.collection;
                if (acc[collection] === undefined) {
                    acc[collection] = 0;
                }
                if (nft._checked) {
                    acc[collection] += 1;
                }
                return acc;
            },
            {} as Record<string, number>
        );
        const c = Object.values(countCheckedPerCollection);
        for (let i = 0; i < c.length; i++) {
            if (c[i] !== c[0]) {
                return false;
            }
        }
        return true;
    }, [walletNfts]);

    const currentTimestamp = Math.floor(Date.now() / 1000);

    const sectionCounts = useMemo(() => {
        return {
            [Section.staked]: stakedNfts.length,
            [Section.unstaked]: unstakedNfts.length,
            [Section.wallet]: walletNfts.length
        };
    }, [stakedNfts, unstakedNfts, walletNfts]);

    // Fetch staked NFTs
    useEffect(() => {
        if (userStaking.length === 0) {
            setStakedNfts([]);
            return;
        }
        if (userStaking.length === stakedNfts.length) return;

        const stakedIdentifiers = userStaking
            .map((sp) => {
                return {
                    token: sp.token ?? stakingToken!,
                    nonce: sp.nonce!.toString(16)
                };
            })
            .map((sp) => {
                return (
                    sp.token +
                    '-' +
                    (sp.nonce.length % 2 === 1 ? '0' + sp.nonce : sp.nonce)
                );
            });

        apiNetworkProvider
            .getNftsWithIdentifiers(stakedIdentifiers)
            .then((_stakedNfts) => {
                setStakedNfts(_stakedNfts);
            });
    }, [userStaking]);

    // Fetch unstaked NFTs
    useEffect(() => {
        if (userUnstaking.length === 0) {
            setUnstakedNfts([]);
            return;
        }
        if (userUnstaking.length === unstakedNfts.length) return;

        const unstakedIdentifiers = userUnstaking
            .map((sp) => {
                return {
                    token: sp.token ?? stakingToken!,
                    nonce: sp.nonce!.toString(16)
                };
            })
            .map((sp) => {
                return (
                    sp.token +
                    '-' +
                    (sp.nonce.length % 2 === 1 ? '0' + sp.nonce : sp.nonce)
                );
            });

        apiNetworkProvider
            .getNftsWithIdentifiers(unstakedIdentifiers)
            .then((_unstakedNfts) => {
                _unstakedNfts.forEach((nft) => {
                    const unstakedPosition = userUnstaking.find(
                        (sp) => sp.nonce === nft.nonce
                    );
                    if (unstakedPosition) {
                        nft._unstakingPosition = unstakedPosition;
                    }
                });
                setUnstakedNfts(_unstakedNfts);
            });
    }, [userStaking]);

    // Fetch wallet NFTs
    useEffect(() => {
        apiNetworkProvider
            .getAccountNftsFromCollections(
                address,
                stakingTokens ?? [stakingToken!]
            )
            .then((res) => {
                setWalletNfts(
                    res.filter((nft) => {
                        if (
                            _address ===
                            'erd1qqqqqqqqqqqqqpgq88faj957nkqmwx9429ycsaa3cr88rkgjdn3qx4hrpz'
                        ) {
                            //OAG
                            const attribute = nft.metadata?.attributes?.find(
                                (a: any) => a.trait_type === 'Fur'
                            );
                            if (!attribute) {
                                return false;
                            }
                            return (
                                attribute.value === 'Trippy' ||
                                attribute.value === 'Gold' ||
                                attribute.value === 'Robot' ||
                                attribute.value === 'Legendary'
                            );
                        }
                        if (
                            _address ===
                            'erd1qqqqqqqqqqqqqpgqsxu0745t995t9gxylvt9xls07dssvnjmdn3qn7sj4h'
                        ) {
                            //EVOAG
                            const attribute = nft.metadata?.attributes?.find(
                                (a: any) => a.trait_type === 'Fur'
                            );
                            if (!attribute) {
                                return false;
                            }
                            return (
                                attribute.value === 'Trippy' ||
                                attribute.value === 'Gold' ||
                                attribute.value === 'Robot'
                            );
                        }
                        if (
                            _address ===
                            'erd1qqqqqqqqqqqqqpgq0g3jdnd2jlw2nh0zxs4ghedr3qmwnntkdn3qha9myl'
                        ) {
                            //Cyborg Bald
                            const attribute = nft.metadata?.attributes?.find(
                                (a: any) => a.trait_type === 'Hair'
                            );
                            if (!attribute) {
                                return false;
                            }
                            return attribute.value === 'BaldLegendary';
                        }
                        if (
                            _address ===
                            'erd1qqqqqqqqqqqqqpgqchw9422fu5m6205qggc8u28ddtdw8jxudn3qtl6pk5'
                        ) {
                            //OAG Graffiti
                            const attribute = nft.metadata?.attributes?.find(
                                (a: any) => a.trait_type === 'Hat'
                            );
                            if (!attribute) {
                                return false;
                            }
                            return attribute.value === 'Graffiti Crown';
                        }
                        if (
                            _address ===
                            'erd1qqqqqqqqqqqqqpgqpxqgzyuyna7kmxh5hf9fmsur4m3lt37udn3qs3rhqg'
                        ) {
                            //EVOAG Graffiti
                            const attribute = nft.metadata?.attributes?.find(
                                (a: any) => a.trait_type === 'Hat'
                            );
                            if (!attribute) {
                                return false;
                            }
                            return attribute.value === 'Graffiti Crown';
                        }
                        return true;
                    })
                );
                if (res.length > 0) {
                    setInputBoostCard(res[0].identifier);
                }
            });
    }, [userStaking, userUnstaking]);

    const editionToBoostPercentage = (edition: string) => {
        if (edition === 'Seoul') {
            return 30;
        }
        if (edition === 'Bucharest') {
            return 20;
        }
        if (edition === 'Paris') {
            return 10;
        }
    };

    const changeCheckedStaked = (index: number, checked: boolean) => {
        const _stakedNfts = [...stakedNfts];
        _stakedNfts[index]._checked = checked;
        setStakedNfts(_stakedNfts);
    };
    const changeCheckedUnstaked = (index: number, checked: boolean) => {
        const _unstakedNfts = [...unstakedNfts];
        _unstakedNfts[index]._checked = checked;
        setUnstakedNfts(_unstakedNfts);
    };
    const changeCheckedWallet = (index: number, checked: boolean) => {
        const _walletNfts = [...walletNfts];
        _walletNfts[index]._checked = checked;
        setWalletNfts(_walletNfts);
    };

    const stakeNfts = async (stakeAll: boolean = false) => {
        const nftsToStake = walletNfts.filter(
            (nft) => nft._checked || stakeAll
        );

        const tokenPayments: TokenPayment[] = nftsToStake.map((nft) =>
            TokenPayment.nonFungible(nft.collection, nft.nonce)
        );
        const payload =
            new TransferTransactionsFactory(new GasEstimator())
                .createMultiESDTNFTTransfer({
                    tokenTransfers: tokenPayments,
                    destination: new Address(_address),
                    sender: new Address(address),
                    chainID
                })
                .getData()
                .toString() +
            '@' +
            string2hex('stake');

        await refreshAccount();

        const { sessionId } = await sendTransactions({
            transactions: {
                value: 0,
                data: payload,
                receiver: address,
                gasLimit: 3_500_000 + 3_500_000 * nftsToStake.length
            },
            transactionsDisplayInfo: {
                processingMessage: 'Staking NFTs...',
                errorMessage: 'An error has occured during staking',
                successMessage: 'NFTs staked successfully!'
            }
        });
    };

    const unstakeNfts = async (unstakeAll: boolean = false) => {
        const nftsToUnstake = stakedNfts.filter(
            (nft) => nft._checked || unstakeAll
        );
        const payload =
            'unstake@' +
            nftsToUnstake
                .map(
                    (nft) =>
                        (stakingTokens
                            ? string2hex(nft.collection) + '@'
                            : '') + nft.identifier.split('-')[2]
                )
                .join('@');

        await refreshAccount();

        const { sessionId } = await sendTransactions({
            transactions: {
                value: 0,
                data: payload,
                receiver: _address,
                gasLimit: 20_000_000 + 20_000_000 * nftsToUnstake.length
            },
            transactionsDisplayInfo: {
                processingMessage: 'Unstaking NFTs...',
                errorMessage: 'An error has occured during unstaking',
                successMessage: 'NFTs unstaked successfully!'
            }
        });
    };

    const withdrawNfts = async (withdrawAll: boolean = false) => {
        const nftsToWithdraw = unstakedNfts
            .filter(
                (nft) =>
                    nft._unstakingPosition.unstake_deadline! < currentTimestamp
            )
            .filter((nft) => nft._checked || withdrawAll);

        const payload =
            'withdraw@' +
            nftsToWithdraw
                .map(
                    (nft) =>
                        (stakingTokens
                            ? string2hex(nft.collection) + '@'
                            : '') + nft.identifier.split('-')[2]
                )
                .join('@');

        await refreshAccount();

        const { sessionId } = await sendTransactions({
            transactions: {
                value: 0,
                data: payload,
                receiver: _address,
                gasLimit: 10_000_000 + 10_000_000 * nftsToWithdraw.length
            },
            transactionsDisplayInfo: {
                processingMessage: 'Withdrawing NFTs...',
                errorMessage: 'An error has occured during withdrawing',
                successMessage: 'NFTs withdrawn successfully!'
            }
        });
    };

    const cancelUnstaking = async (cancelAll: boolean = false) => {
        const nftsToCancel = unstakedNfts
            .filter(
                (nft) =>
                    nft._unstakingPosition.unstake_deadline! >= currentTimestamp
            )
            .filter((nft) => nft._checked || cancelAll);

        const payload =
            'cancelUnstake@' +
            nftsToCancel
                .map(
                    (nft) =>
                        (stakingTokens
                            ? string2hex(nft.collection) + '@'
                            : '') + nft.identifier.split('-')[2]
                )
                .join('@');

        await refreshAccount();

        const { sessionId } = await sendTransactions({
            transactions: {
                value: 0,
                data: payload,
                receiver: _address,
                gasLimit: 10_000_000 + 6_000_000 * nftsToCancel.length
            },
            transactionsDisplayInfo: {
                processingMessage: 'Canceling unstaking NFTs...',
                errorMessage: 'An error has occured during canceling',
                successMessage: 'NFTs canceling successfully!'
            }
        });
    };

    const claimRewards = async (token: string = '') => {
        await refreshAccount();

        const { sessionId } = await sendTransactions({
            transactions: {
                value: 0,
                data: 'claimRewards' + (token ? '@' + string2hex(token) : ''),
                receiver: _address,
                gasLimit: 25_000_000
            },
            transactionsDisplayInfo: {
                processingMessage: 'Claiming rewards...',
                errorMessage: 'An error has occured during claiming',
                successMessage: 'Rewards claimed successfully!'
            }
        });
    };

    const boost = async () => {
        const nftToSend = walletNfts.find(
            (nft) => nft.identifier === inputBoostCard
        )!;
        console.log({ nftToSend });
        const payload =
            new TransferTransactionsFactory(new GasEstimator())
                .createESDTNFTTransfer({
                    tokenTransfer: TokenPayment.nonFungible(
                        nftToSend.collection,
                        nftToSend.nonce
                    ),
                    destination: new Address(_address),
                    sender: new Address(address),
                    chainID
                })
                .getData()
                .toString() +
            '@' +
            string2hex('addBoostCard');

        await refreshAccount();

        const { sessionId } = await sendTransactions({
            transactions: {
                value: 0,
                data: payload,
                receiver: address,
                gasLimit: 20_000_000
            },
            transactionsDisplayInfo: {
                processingMessage: 'Boosting position...',
                errorMessage: 'An error has occured during boost',
                successMessage: 'Position boosted successfully!'
            }
        });
    };

    const addressToName = (address: string) => {
        if (
            address ===
            'erd1qqqqqqqqqqqqqpgqd7f32re7pj0xqha8zru8kgwsqepvhn8kdn3qxej29v'
        ) {
            return 'Infinity Staking Pool';
        }
        if (
            address ===
            'erd1qqqqqqqqqqqqqpgqyyfevpc7p9fjt3eaaumyz5cqxhvle0d4dn3qhaj2mh'
        ) {
            return 'OAG Pool';
        }
        if (
            address ===
            'erd1qqqqqqqqqqqqqpgqjenyd0en0nrrgdat4jwpjsjr7szmf3lgdn3qjhuu4m'
        ) {
            return 'EVOAG Pool';
        }
        if (
            address ===
            'erd1qqqqqqqqqqqqqpgqfmkxuwjazrgup0he2j2ryrkhld8pfu9ydn3qxxpgze'
        ) {
            return 'Duo Pool';
        }
        if (
            address ===
            'erd1qqqqqqqqqqqqqpgqe98h78xksheffcy2n3nwmdj5hfsmlvkxdn3qlttq96'
        ) {
            return 'Trio Pool';
        }
        if (
            address ===
            'erd1qqqqqqqqqqqqqpgqv7c5juqtewtcc9krjnt0jhmudgas3hzqdn3qtny67p'
        ) {
            return 'E.V Membership Cards Pool';
        }
        if (
            address ===
            'erd1qqqqqqqqqqqqqpgq88faj957nkqmwx9429ycsaa3cr88rkgjdn3qx4hrpz'
        ) {
            return 'OAG Prime Pool';
        }
        if (
            address ===
            'erd1qqqqqqqqqqqqqpgqsxu0745t995t9gxylvt9xls07dssvnjmdn3qn7sj4h'
        ) {
            return 'EVOAG Prime Pool';
        }
        if (
            address ===
            'erd1qqqqqqqqqqqqqpgq0g3jdnd2jlw2nh0zxs4ghedr3qmwnntkdn3qha9myl'
        ) {
            return 'Legendary Bald';
        }
        if (
            address ===
            'erd1qqqqqqqqqqqqqpgqchw9422fu5m6205qggc8u28ddtdw8jxudn3qtl6pk5'
        ) {
            return 'Graffiti Crown OAG';
        }
        if (
            address ===
            'erd1qqqqqqqqqqqqqpgqpxqgzyuyna7kmxh5hf9fmsur4m3lt37udn3qs3rhqg'
        ) {
            return 'Graffiti Crown EVOAG';
        }
        if (
            address ===
            'erd1qqqqqqqqqqqqqpgqfw5l86j2g0qsn9r3xfp89l692326qa44dn3ql4txsr'
        ) {
            return 'Badges Pool';
        }
        return address;
    };

    const addressToDescription = (address: string) => {
        if (
            address ===
            'erd1qqqqqqqqqqqqqpgqd7f32re7pj0xqha8zru8kgwsqepvhn8kdn3qxej29v'
        ) {
            return '0.5 $LKUNITYX/Day per nft';
        }
        if (
            address ===
            'erd1qqqqqqqqqqqqqpgq0g3jdnd2jlw2nh0zxs4ghedr3qmwnntkdn3qha9myl'
        ) {
            return 'The max reward/day is fixed at 5 $UTX/NFT';
        }
        if (
            address ===
            'erd1qqqqqqqqqqqqqpgqv7c5juqtewtcc9krjnt0jhmudgas3hzqdn3qtny67p'
        ) {
            return 'Fixed rewards 0.15 $UTX/NFT daily.';
        }
        if (
            address ===
            'erd1qqqqqqqqqqqqqpgqfw5l86j2g0qsn9r3xfp89l692326qa44dn3ql4txsr'
        ) {
            return 'Fixed rewards 0.23 $UTX/NFT daily.';
        }
        return;
    };

    const tokenToPath = (token?: string) => {
        if (token === utxIdentifier) {
            return UtxLogo;
        }
        if (token === lkunityxIdentifier) {
            return LkunityxLogo;
        }
        return MultiversxSymbol;
    };

    const isTrio = tickerCombo === 'EVCYB-aea8b4&OAG-0eaf3b&EVOAG-1a4f7d';
    const isDuo = tickerCombo === 'OAG-0eaf3b&EVOAG-1a4f7d';
    const isBald =
        _address ===
        'erd1qqqqqqqqqqqqqpgq0g3jdnd2jlw2nh0zxs4ghedr3qmwnntkdn3qha9myl';
    const isISP =
        _address ===
        'erd1qqqqqqqqqqqqqpgqd7f32re7pj0xqha8zru8kgwsqepvhn8kdn3qxej29v';
    const isPrime =
        _address ===
            'erd1qqqqqqqqqqqqqpgq88faj957nkqmwx9429ycsaa3cr88rkgjdn3qx4hrpz' ||
        _address ==
            'erd1qqqqqqqqqqqqqpgqsxu0745t995t9gxylvt9xls07dssvnjmdn3qn7sj4h';
    const isGraffiti =
        _address ===
            'erd1qqqqqqqqqqqqqpgqchw9422fu5m6205qggc8u28ddtdw8jxudn3qtl6pk5' ||
        _address ==
            'erd1qqqqqqqqqqqqqpgqpxqgzyuyna7kmxh5hf9fmsur4m3lt37udn3qs3rhqg';
    const isOagOrEvoag =
        address ===
            'erd1qqqqqqqqqqqqqpgqjenyd0en0nrrgdat4jwpjsjr7szmf3lgdn3qjhuu4m' ||
        address ===
            'erd1qqqqqqqqqqqqqpgqyyfevpc7p9fjt3eaaumyz5cqxhvle0d4dn3qhaj2mh';

    const [showStaked, setShowStaked] = useState(false);
    const [showUnstaked, setShowUnstaked] = useState(false);
    const [showWallet, setShowWallet] = useState(false);

    const handleCloseStaked = () => setShowStaked(false);
    const handleShowStaked = () => setShowStaked(true);
    const handleCloseUnstaked = () => setShowUnstaked(false);
    const handleShowUnstaked = () => setShowUnstaked(true);
    const handleCloseWallet = () => setShowWallet(false);
    const handleShowWallet = () => setShowWallet(true);

    return (
        <div className='single-staking-card w-100 w-md-50 d-flex justify-content-center gap-4 '>
            <div
                className='card card-dashboard text-center relative overflow-visble'
                style={{
                    backgroundImage: isTrio
                        ? 'linear-gradient(135deg, rgb(253 252 251 / 0%) 0%, rgb(226 195 226 / 33%) 100%)'
                        : ''
                }}
            >
                {isTrio && (
                    <div className='staking-card-booster position-absolute z-100'>
                        33% BOOST
                    </div>
                )}
                {isDuo && (
                    <div className='staking-card-booster position-absolute z-100'>
                        10% BOOST
                    </div>
                )}
                {isBald && (
                    <div className='staking-card-booster position-absolute z-100'>
                        x5 FP
                    </div>
                )}
                {isISP && (
                    <div className='staking-card-booster position-absolute z-100'>
                        Double rewards!
                    </div>
                )}
                {isOagOrEvoag && (
                    <div className='staking-card-booster position-absolute z-100'>
                        x0.8 FP
                    </div>
                )}
                {isPrime && (
                    <div className='staking-card-booster position-absolute z-100'>
                        x2 FP
                    </div>
                )}
                {isGraffiti && (
                    <div className='staking-card-booster position-absolute z-100'>
                        x3 FP
                    </div>
                )}
                {stakingToken && (
                    <BuyOnMarketplace
                        stakingToken={stakingToken}
                    ></BuyOnMarketplace>
                )}
                <div className='w-full d-flex flex-column flex-wrap justify-content-center'>
                    <div className='my-4 w-100'>
                        <span className='nft-title'>
                            {addressToName(_address!)}
                            <span className='nft-subtitle'>
                                {addressToDescription(_address!)}
                            </span>
                        </span>
                        <div className='claim-button-container mt-3'>
                            <button
                                className='claim-button btn btn-secondary btn-custom px-4'
                                disabled={
                                    rewardsForUser.filter(
                                        (reward) =>
                                            reward.amount?.toString() !== '0'
                                    ).length === 0
                                }
                                onClick={() => claimRewards()}
                            >
                                Claim all rewards
                            </button>
                            {/* <FontAwesomeIcon
                  icon={expanded ? faChevronUp : faChevronDown}
                  className="ml-auto my-auto fa-lg arrow-custom mr-2"
                /> */}
                        </div>
                        <input
                            type='checkbox'
                            className='form-control form-check-input nft-checkbox d-none '
                            id={'check-' + _address}
                            checked={expanded}
                            onChange={(e) => setExpanded((prev) => !prev)}
                        />
                        <label
                            htmlFor={'check-' + _address}
                            className={
                                'nft-staking-expanded' +
                                (expanded ? 'nft-staking-expanded' : '')
                            }
                        ></label>
                        <div className={'nft-staking-card-body expanded'}>
                            <div className='mb-4 d-md-flex justify-content-between container-buttons-staking'>
                                <div className='mr-4 btn-group btn-group-toggle'>
                                    {section === Section.staked && (
                                        <div className='unstake-buttons-container'>
                                            <button
                                                className='unstake-single-btn btn btn-md px-4 btn-outline-primary section-toggler-custom'
                                                disabled={
                                                    stakedNfts.filter(
                                                        (nft) => nft._checked
                                                    ).length === 0 ||
                                                    !stakedNftsCheckedCountEqualPerCollection
                                                }
                                                onClick={() => unstakeNfts()}
                                            >
                                                Unstake
                                            </button>
                                            <button
                                                className='unstake-all-btn btn btn-md px-4 btn-outline-primary section-toggler-custom'
                                                disabled={
                                                    stakedNfts.length === 0
                                                }
                                                onClick={() =>
                                                    unstakeNfts(true)
                                                }
                                            >
                                                Unstake All
                                            </button>
                                        </div>
                                    )}
                                    {section === Section.wallet && (
                                        <div className='unstake-buttons-container'>
                                            <button
                                                className='unstake-single-btn btn btn-md px-4 btn-outline-primary section-toggler-custom'
                                                disabled={
                                                    walletNfts.filter(
                                                        (nft) => nft._checked
                                                    ).length === 0 ||
                                                    !walletNftsCheckedCountEqualPerCollection
                                                }
                                                onClick={() => stakeNfts()}
                                            >
                                                Stake
                                            </button>
                                            <button
                                                className='unstake-single-btn btn btn-md px-4 btn-outline-primary section-toggler-custom'
                                                disabled={
                                                    walletNfts.length === 0
                                                }
                                                onClick={() => stakeNfts(true)}
                                            >
                                                Stake All
                                            </button>
                                        </div>
                                    )}
                                </div>
                                <SectionSelector
                                    section={section}
                                    sections={[...Object.values(Section)]}
                                    sectionCounts={sectionCounts}
                                    setSection={setSection}
                                    className='mr-4'
                                />
                            </div>

                            {section === Section.staked &&
                                stakingTokens &&
                                stakedNfts.filter((t) => t._checked).length >
                                    0 &&
                                !stakedNftsCheckedCountEqualPerCollection && (
                                    <div className='text-danger text-center my-2'>
                                        You need to unstake the same amount of
                                        NFTs from each collection
                                    </div>
                                )}
                            {section === Section.wallet &&
                                stakingTokens &&
                                walletNfts.filter((t) => t._checked).length >
                                    0 &&
                                !walletNftsCheckedCountEqualPerCollection && (
                                    <div className='text-danger text-center my-2'>
                                        You need to stake the same amount of
                                        NFTs from each collection
                                    </div>
                                )}
                            <div className='nft-container position-relative relative-staked'>
                                {userStaking.length != 0 &&
                                    section === Section.staked && (
                                        <a
                                            onClick={handleShowStaked}
                                            className='position-absolute'
                                            style={{
                                                top: 0,
                                                left: 0,
                                                zIndex: 1000
                                            }}
                                        >
                                            <svg
                                                fill='white'
                                                xmlns='http://www.w3.org/2000/svg'
                                                width='32'
                                                height='32'
                                                viewBox='0 0 24 24'
                                            >
                                                <path d='M12 10c1.104 0 2 .896 2 2s-.896 2-2 2-2-.896-2-2 .896-2 2-2zm-3.857 3c-.084-.321-.143-.652-.143-1s.059-.679.143-1h-2.143v-4l-6 5 6 5v-4h2.143zm7.714-2c.084.321.143.652.143 1s-.059.679-.143 1h2.143v4l6-5-6-5v4h-2.143zm-2.857 4.857c-.321.084-.652.143-1 .143s-.679-.059-1-.143v2.143h-4l5 6 5-6h-4v-2.143zm-2-7.714c.321-.084.652-.143 1-.143s.679.059 1 .143v-2.143h4l-5-6-5 6h4v2.143z' />
                                            </svg>
                                        </a>
                                    )}
                                {userUnstaking.length != 0 &&
                                    section === Section.unstaked && (
                                        <a
                                            onClick={handleShowUnstaked}
                                            className='position-absolute'
                                            style={{
                                                top: 0,
                                                left: 0,
                                                zIndex: 1000
                                            }}
                                        >
                                            <svg
                                                fill='white'
                                                xmlns='http://www.w3.org/2000/svg'
                                                width='32'
                                                height='32'
                                                viewBox='0 0 24 24'
                                            >
                                                <path d='M12 10c1.104 0 2 .896 2 2s-.896 2-2 2-2-.896-2-2 .896-2 2-2zm-3.857 3c-.084-.321-.143-.652-.143-1s.059-.679.143-1h-2.143v-4l-6 5 6 5v-4h2.143zm7.714-2c.084.321.143.652.143 1s-.059.679-.143 1h2.143v4l6-5-6-5v4h-2.143zm-2.857 4.857c-.321.084-.652.143-1 .143s-.679-.059-1-.143v2.143h-4l5 6 5-6h-4v-2.143zm-2-7.714c.321-.084.652-.143 1-.143s.679.059 1 .143v-2.143h4l-5-6-5 6h4v2.143z' />
                                            </svg>
                                        </a>
                                    )}
                                {walletNfts.length != 0 &&
                                    section === Section.wallet && (
                                        <a
                                            onClick={handleShowWallet}
                                            className='position-absolute'
                                            style={{
                                                top: 0,
                                                left: 0,
                                                zIndex: 1000
                                            }}
                                        >
                                            <svg
                                                fill='white'
                                                xmlns='http://www.w3.org/2000/svg'
                                                width='32'
                                                height='32'
                                                viewBox='0 0 24 24'
                                            >
                                                <path d='M12 10c1.104 0 2 .896 2 2s-.896 2-2 2-2-.896-2-2 .896-2 2-2zm-3.857 3c-.084-.321-.143-.652-.143-1s.059-.679.143-1h-2.143v-4l-6 5 6 5v-4h2.143zm7.714-2c.084.321.143.652.143 1s-.059.679-.143 1h2.143v4l6-5-6-5v4h-2.143zm-2.857 4.857c-.321.084-.652.143-1 .143s-.679-.059-1-.143v2.143h-4l5 6 5-6h-4v-2.143zm-2-7.714c.321-.084.652-.143 1-.143s.679.059 1 .143v-2.143h4l-5-6-5 6h4v2.143z' />
                                            </svg>
                                        </a>
                                    )}
                                {section === Section.staked &&
                                    userStaking.length === 0 && (
                                        <PageState
                                            icon={faGrip}
                                            iconSize='3x'
                                            title="You don't have any staked NFT. Stake some to start earning rewards!"
                                        />
                                    )}
                                {section === Section.staked && (
                                    <Swiper
                                        effect={'cards'}
                                        grabCursor={true}
                                        className='swiperStakedNfts'
                                        slidesPerView={'auto'}
                                        spaceBetween={30}
                                        centeredSlides={true}
                                        pagination={{
                                            dynamicBullets: true
                                        }}
                                        modules={[Pagination]}
                                    >
                                        {stakedNfts.map((nft, i) => (
                                            <SwiperSlide key={i}>
                                                <NftVisualizer
                                                    nft={nft}
                                                    contract={_address}
                                                    changeCallback={(
                                                        checked: boolean
                                                    ) =>
                                                        changeCheckedStaked(
                                                            i,
                                                            checked
                                                        )
                                                    }
                                                />
                                            </SwiperSlide>
                                        ))}
                                    </Swiper>
                                )}
                                <Modal
                                    show={showStaked}
                                    onHide={handleCloseStaked}
                                >
                                    <Modal.Header className='d-flex flex-column stakedNfts'>
                                        <h4>NFTs Overview.</h4>
                                        <p>
                                            Here you can have a better overview
                                            of your nfts, select them here and
                                            perform actions as normal.
                                        </p>
                                    </Modal.Header>
                                    <Modal.Body className='modal-nfts'>
                                        {stakedNfts.map((nft, i) => (
                                            <NftVisualizer
                                                nft={nft}
                                                contract={_address}
                                                changeCallback={(
                                                    checked: boolean
                                                ) =>
                                                    changeCheckedStaked(
                                                        i,
                                                        checked
                                                    )
                                                }
                                            />
                                        ))}
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button
                                            variant='secondary'
                                            onClick={handleCloseStaked}
                                        >
                                            Close
                                        </Button>
                                    </Modal.Footer>
                                </Modal>

                                <Modal
                                    show={showUnstaked}
                                    onHide={handleCloseUnstaked}
                                >
                                    <Modal.Header className='d-flex flex-column unstakedNfts'>
                                        <h4>NFTs Overview.</h4>
                                        <p>
                                            Here you can have a better overview
                                            of your nfts, select them here and
                                            perform actions as normal.
                                        </p>
                                    </Modal.Header>
                                    <Modal.Body className='modal-nfts'>
                                        {unstakedNfts.map((nft, i) => (
                                            <NftVisualizer
                                                nft={nft}
                                                contract={_address}
                                                changeCallback={(
                                                    checked: boolean
                                                ) =>
                                                    changeCheckedStaked(
                                                        i,
                                                        checked
                                                    )
                                                }
                                            />
                                        ))}
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button
                                            variant='secondary'
                                            onClick={handleCloseUnstaked}
                                        >
                                            Close
                                        </Button>
                                    </Modal.Footer>
                                </Modal>
                                <Modal
                                    show={showWallet}
                                    onHide={handleCloseWallet}
                                >
                                    <Modal.Header className='d-flex flex-column walletNfts'>
                                        <h4>NFTs Overview.</h4>
                                        <p>
                                            Here you can have a better overview
                                            of your nfts, select them here and
                                            perform actions as normal.
                                        </p>
                                    </Modal.Header>
                                    <Modal.Body className='modal-nfts'>
                                        {walletNfts.map((nft, i) => (
                                            <NftVisualizer
                                                nft={nft}
                                                contract={_address}
                                                changeCallback={(
                                                    checked: boolean
                                                ) =>
                                                    changeCheckedStaked(
                                                        i,
                                                        checked
                                                    )
                                                }
                                            />
                                        ))}
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button
                                            variant='secondary'
                                            onClick={handleCloseWallet}
                                        >
                                            Close
                                        </Button>
                                    </Modal.Footer>
                                </Modal>

                                {section === Section.unstaked &&
                                    userUnstaking.length === 0 && (
                                        <PageState
                                            icon={faGrip}
                                            title="You don't have any unstaked NFT."
                                        />
                                    )}
                                {section === Section.unstaked &&
                                    userUnstaking.length !== 0 && (
                                        <div className='w-100'>
                                            <div className='d-flex flex-column justify-content-between pb-3'>
                                                <h4>Ready to Withdraw</h4>
                                                <div className='unstake-buttons-container'>
                                                    <button
                                                        className='unstake-single-btn btn btn-lg px-4 btn-outline-primary mr-1 section-toggler-custom'
                                                        disabled={
                                                            unstakedNfts
                                                                .filter(
                                                                    (nft) =>
                                                                        nft
                                                                            ._unstakingPosition
                                                                            .unstake_deadline! <
                                                                        currentTimestamp
                                                                )
                                                                .filter(
                                                                    (nft) =>
                                                                        nft._checked
                                                                ).length === 0
                                                        }
                                                        onClick={() =>
                                                            withdrawNfts()
                                                        }
                                                    >
                                                        Withdraw
                                                    </button>
                                                    <button
                                                        className='unstake-single-btn btn btn-lg px-4 btn-outline-primary section-toggler-custom'
                                                        disabled={
                                                            unstakedNfts.filter(
                                                                (nft) =>
                                                                    nft
                                                                        ._unstakingPosition
                                                                        .unstake_deadline! <
                                                                    currentTimestamp
                                                            ).length === 0
                                                        }
                                                        onClick={() =>
                                                            withdrawNfts(true)
                                                        }
                                                    >
                                                        Withdraw All
                                                    </button>
                                                </div>
                                            </div>
                                            <div className='nft-container position-relative'>
                                                <Swiper
                                                    effect={'cards'}
                                                    grabCursor={true}
                                                    className='swiperStakedNfts'
                                                    slidesPerView={'auto'}
                                                    spaceBetween={30}
                                                    centeredSlides={true}
                                                    pagination={{
                                                        dynamicBullets: true
                                                    }}
                                                    modules={[Pagination]}
                                                >
                                                    {unstakedNfts
                                                        .filter(
                                                            (nft) =>
                                                                nft
                                                                    ._unstakingPosition
                                                                    .unstake_deadline! <
                                                                currentTimestamp
                                                        )
                                                        .map((nft, i) => (
                                                            <SwiperSlide
                                                                key={i}
                                                            >
                                                                <NftVisualizer
                                                                    nft={nft}
                                                                    contract={
                                                                        _address
                                                                    }
                                                                    changeCallback={(
                                                                        checked: boolean
                                                                    ) =>
                                                                        changeCheckedUnstaked(
                                                                            i,
                                                                            checked
                                                                        )
                                                                    }
                                                                />
                                                            </SwiperSlide>
                                                        ))}
                                                </Swiper>
                                            </div>
                                            <div className='d-flex flex-column justify-content-between pb-3'>
                                                <h3>
                                                    Pending Unstake (
                                                    {new BigNumber(
                                                        unstakingPeriod!
                                                    )
                                                        .dividedBy(3600)
                                                        .toFixed(0)}
                                                    &nbsp;hours unstaking
                                                    period)
                                                </h3>
                                                <div className='unstake-buttons-container'>
                                                    <button
                                                        className='unstake-single-btn btn btn-lg px-4 btn-outline-primary mr-1'
                                                        disabled={
                                                            unstakedNfts
                                                                .filter(
                                                                    (nft) =>
                                                                        nft
                                                                            ._unstakingPosition
                                                                            .unstake_deadline! >=
                                                                        currentTimestamp
                                                                )
                                                                .filter(
                                                                    (nft) =>
                                                                        nft._checked
                                                                ).length === 0
                                                        }
                                                        onClick={() =>
                                                            cancelUnstaking()
                                                        }
                                                    >
                                                        Cancel
                                                    </button>
                                                    <button
                                                        className='unstake-single-btn btn btn-lg px-4 btn-outline-primary'
                                                        disabled={
                                                            unstakedNfts.filter(
                                                                (nft) =>
                                                                    nft
                                                                        ._unstakingPosition
                                                                        .unstake_deadline! >=
                                                                    currentTimestamp
                                                            ).length === 0
                                                        }
                                                        onClick={() =>
                                                            cancelUnstaking(
                                                                true
                                                            )
                                                        }
                                                    >
                                                        Cancel All
                                                    </button>
                                                </div>
                                            </div>
                                            <div className='nft-container'>
                                                <Swiper
                                                    effect={'cards'}
                                                    grabCursor={true}
                                                    className='swiperStakedNfts'
                                                    slidesPerView={'auto'}
                                                    spaceBetween={30}
                                                    centeredSlides={true}
                                                    pagination={{
                                                        dynamicBullets: true
                                                    }}
                                                    modules={[Pagination]}
                                                >
                                                    {unstakedNfts
                                                        .filter(
                                                            (nft) =>
                                                                nft
                                                                    ._unstakingPosition
                                                                    .unstake_deadline! >=
                                                                currentTimestamp
                                                        )
                                                        .map((nft, i) => (
                                                            <SwiperSlide
                                                                key={i}
                                                            >
                                                                <NftVisualizer
                                                                    nft={nft}
                                                                    contract={
                                                                        _address
                                                                    }
                                                                    changeCallback={(
                                                                        checked: boolean
                                                                    ) =>
                                                                        changeCheckedUnstaked(
                                                                            i,
                                                                            checked
                                                                        )
                                                                    }
                                                                />
                                                            </SwiperSlide>
                                                        ))}
                                                </Swiper>
                                            </div>
                                        </div>
                                    )}
                                {section === Section.wallet &&
                                    walletNfts.length === 0 && (
                                        <PageState
                                            icon={faGrip}
                                            title="You don't have any NFT in your wallet"
                                        />
                                    )}
                                {section === Section.wallet && (
                                    <Swiper
                                        effect={'cards'}
                                        grabCursor={true}
                                        className='swiperStakedNfts'
                                        slidesPerView={'auto'}
                                        spaceBetween={30}
                                        centeredSlides={true}
                                        pagination={{
                                            dynamicBullets: true
                                        }}
                                        modules={[Pagination]}
                                    >
                                        {walletNfts.map((nft, i) => (
                                            <SwiperSlide key={i}>
                                                <NftVisualizer
                                                    nft={nft}
                                                    contract={_address}
                                                    changeCallback={(checked) =>
                                                        changeCheckedWallet(
                                                            i,
                                                            checked
                                                        )
                                                    }
                                                />
                                            </SwiperSlide>
                                        ))}
                                    </Swiper>
                                )}
                            </div>
                        </div>
                        <span
                            className='nft-rewards d-flex flex-column flex-md-row'
                            style={{ marginBottom: 'auto', marginTop: 'auto' }}
                        >
                            <span className='text-light my-3'>
                                Rewards:&nbsp;
                            </span>
                        </span>
                        <div className='rewards-value-container'>
                            {rewardsForUser.map((reward, i, a) => (
                                <div className='d-flex flex-row '>
                                    <div className='d-flex flex-row align-items-center position-relative'>
                                        <img
                                            src={tokenToPath(reward.tokenId)}
                                            alt='Logo'
                                            className='nft-token-icon mr-2'
                                        />
                                        <FormatAmount
                                            value={reward.amount!.toString(10)}
                                            showLabel={false}
                                            digits={5}
                                            decimals={18}
                                        />
                                        {tokenPrice &&
                                            reward.tokenId ===
                                                utxIdentifier && (
                                                <span className='mx-2 equivalent-usdc'>
                                                    ~&nbsp;
                                                    {new Decimal(
                                                        reward.amount!.toString(
                                                            10
                                                        )
                                                    )
                                                        .div(10 ** 18)
                                                        .mul(tokenPrice)
                                                        .toSignificantDigits(3)
                                                        .toString()}
                                                    $
                                                </span>
                                            )}
                                    </div>
                                    {a.length > 1 && (
                                        <button
                                            className='claim-button btn btn-sm btn-secondary btn-custom px-4 ml-2'
                                            style={{
                                                lineHeight: '1.2'
                                            }}
                                            disabled={
                                                reward.amount?.toString() ===
                                                '0'
                                            }
                                            onClick={() =>
                                                claimRewards(reward.tokenId!)
                                            }
                                        >
                                            Claim{' '}
                                            {reward.tokenId!.split('-')[0]}
                                        </button>
                                    )}
                                </div>
                            ))}
                        </div>
                        {isBoosted && (
                            <>
                                <hr className='separator' />
                                <div>
                                    <h4>Boost:</h4>
                                    <div className='fs-6'>
                                        {currentUserBoost &&
                                        currentUserBoost.boost_deadline! >
                                            currentTimestamp ? (
                                            <div className='boosted-true'>
                                                Your position is boosted with a{' '}
                                                {currentUserBoost.card_type}{' '}
                                                Card until{' '}
                                                {timestampToDate(
                                                    currentUserBoost.boost_deadline!
                                                )}
                                            </div>
                                        ) : (
                                            <div className='boosted-false'>
                                                Your position is not boosted
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <h4 className='mt-2'>Boost now with:</h4>
                                <select
                                    className='form-control input-amount'
                                    value={inputBoostCard}
                                    onChange={(e) =>
                                        setInputBoostCard(e.target.value)
                                    }
                                >
                                    {walletNfts.map((nft) => (
                                        <option
                                            key={nft.identifier}
                                            value={nft.identifier}
                                        >
                                            {nft.name.replace(
                                                'E.V Badge',
                                                'Badge'
                                            )}{' '}
                                            -{' '}
                                            {nft.metadata?.attributes
                                                ?.find(
                                                    (attr: any) =>
                                                        attr.trait_type ===
                                                        'Edition'
                                                )
                                                ?.value.replace('xDays ', '')}
                                            &nbsp;(
                                            {editionToBoostPercentage(
                                                nft.metadata?.attributes
                                                    ?.find(
                                                        (attr: any) =>
                                                            attr.trait_type ===
                                                            'Edition'
                                                    )
                                                    ?.value.replace(
                                                        'xDays ',
                                                        ''
                                                    )
                                            )}
                                            % boost)
                                        </option>
                                    ))}
                                </select>
                                <button
                                    className='btn mt-2 px-4 btn-outline-primary section-toggler-custom'
                                    onClick={() => boost()}
                                >
                                    Boost
                                </button>
                                {currentUserBoost &&
                                    currentUserBoost.boost_deadline! >
                                        currentTimestamp && (
                                        <div
                                            className='mt-2'
                                            style={{
                                                fontSize: '1rem'
                                            }}
                                        >
                                            Boosting with a new card will
                                            replace your current boost
                                        </div>
                                    )}
                                <div
                                    className='mt-2'
                                    style={{
                                        fontSize: '1rem'
                                    }}
                                >
                                    Every used card will be burned!
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};
