import { useEffect, useState } from 'react';
import { request } from 'graphql-request';
import {
    useGetAccount,
    useGetActiveTransactionsStatus,
    useGetNetworkConfig
} from 'hooks';
import { MyApiNetworkProvider } from 'helpers/MyApiNetworkProvider';
import { graphqlUrl } from 'config';
import { Query, gqlNftStaking } from 'types';
import { NftStakingPoolComponent } from 'components/NftStakingPoolComponent';
import SwiperMembership from 'components/swiperMembership';
import Decimal from 'decimal.js';

export const CollectionsStaking = () => {
    const {
        network: { apiAddress }
    } = useGetNetworkConfig();
    const apiNetworkProvider = new MyApiNetworkProvider(apiAddress);

    const { address } = useGetAccount();
    const { success, fail } = useGetActiveTransactionsStatus();

    const [nftStakingPools, setNftStakingPools] = useState<
        gqlNftStaking[] | undefined
    >();
    const [tokenPrice, setTokenPrice] = useState<Decimal | undefined>();
    const [errorLoading, setErrorLoading] = useState<boolean>(false);
    const [nStakedCards, setNStakedCards] = useState<number | undefined>();
    const [nStakedBadges, setNStakedBadges] = useState<number | undefined>();

    const fetchData = () => {
        request<Query>(
            graphqlUrl,
            `
            query($user: String) {
                ev {
                  nftStakingPools {
                    _address
                    stakingToken
                    stakingTokens
                    rewardTokens
                    rewardsForUser(address:$user) {
                        tokenId
                        amount
                    }
                    userStaking(user:$user) {
                        token
                        nonce
                    }
                    userUnstaking(user:$user) {
                        token
                        nonce
                        unstake_deadline
                    }
                    unstakingPeriod
                    nStakedPositions
                    isBoosted
                    currentUserBoost(user:$user) {
                        boost_deadline
                        card_type
                    }
                  }
                  tokenPrice
                }
              }
            `,
            {
                user: address
            }
        )
            .then(({ ev }) => {
                if (!ev) return;
                if (!ev.nftStakingPools) return;
                setNftStakingPools(
                    ev.nftStakingPools.filter(
                        (p) =>
                            p._address ===
                                'erd1qqqqqqqqqqqqqpgqv7c5juqtewtcc9krjnt0jhmudgas3hzqdn3qtny67p' ||
                            p._address ===
                                'erd1qqqqqqqqqqqqqpgqfw5l86j2g0qsn9r3xfp89l692326qa44dn3ql4txsr'
                    )
                );

                const nStakedCards =
                    ev.nftStakingPools.filter(
                        (p) =>
                            p._address ===
                            'erd1qqqqqqqqqqqqqpgqv7c5juqtewtcc9krjnt0jhmudgas3hzqdn3qtny67p'
                    )[0].nStakedPositions ?? 0;
                setNStakedCards(nStakedCards);

                const nStakedBadges =
                    ev.nftStakingPools.filter(
                        (p) =>
                            p._address ===
                            'erd1qqqqqqqqqqqqqpgqfw5l86j2g0qsn9r3xfp89l692326qa44dn3ql4txsr'
                    )[0].nStakedPositions ?? 0;
                setNStakedBadges(nStakedBadges);

                if (!ev.tokenPrice) return;
                setTokenPrice(new Decimal(ev.tokenPrice));
            })
            .catch((err: any) => {
                console.error(err);
                setErrorLoading(true);
            });
    };

    useEffect(() => {
        fetchData();
    }, [success, fail]);

    if (nftStakingPools === undefined) {
        return (
            <div className='container mt-5'>
                <div>
                    <h1 className='text-left text-custom ev-text'>
                        Collections Staking
                    </h1>
                    <h1 className='text-center'>Loading...</h1>
                </div>
            </div>
        );
    }

    if (errorLoading) {
        return (
            <div className='container mt-5'>
                <div>
                    <h1 className='text-left text-custom ev-text'>
                        Collections Staking
                    </h1>
                    <h1 className='text-center'>
                        Error loading data, please try again later
                    </h1>
                </div>
            </div>
        );
    }

    return (
        <div className='container-fluid w-100 d-flex flex-column flex-lg-row my-5'>
            <div className='dashboard-left w-100 col-lg-9'>
                <h1 className='text-left text-custom ev-text'>
                    Collections Staking
                </h1>
                <div className='staking-cards-container d-flex flex-row flex-wrap gap-5'>
                    {nftStakingPools.map((pool) => (
                        <NftStakingPoolComponent
                            key={pool._address}
                            pool={pool}
                            tokenPrice={tokenPrice}
                        />
                    ))}
                </div>
            </div>
            <div className='dashboard-right col-12 col-lg-3'>
                <div className='dashboard-right-container d-flex flex-column'>
                    <SwiperMembership />
                    <div className='dashboard-right-info'>
                        <div className='p-3 text-center'>
                            <h3 className='font-weight-bold pb-3'>
                                Membership Cards
                            </h3>
                            <div className='card-general-data card card-dashboard text-center relative overflow-visble'>
                                <div className='card-body'>
                                    <h4>Total supply: 5350</h4>
                                    {nStakedCards && (
                                        <h4>Staked: {nStakedCards}</h4>
                                    )}
                                    <h4>Royalties: 8%</h4>
                                </div>
                                <p className='text-danger'>
                                    Collection is sold out
                                </p>
                                <button
                                    className='btn btn-outline-primary btn-md active'
                                    onClick={() =>
                                        window.open(
                                            'https://flipix.io/collection/MEMBCARD-b7e2b9',
                                            '_blank'
                                        )
                                    }
                                >
                                    🛒 Trade now on FLIPiX
                                </button>
                            </div>
                        </div>
                        <div className='p-3 text-center'>
                            <h3 className='font-weight-bold pb-3'>EV Badges</h3>
                            <div className='card-general-data card card-dashboard text-center relative overflow-visble'>
                                <div className='card-body'>
                                    <h4>Total supply: 555</h4>
                                    {nStakedCards && (
                                        <h4>Staked: {nStakedBadges}</h4>
                                    )}
                                    <h4>Royalties: 5%</h4>
                                </div>
                                <p className='text-danger'>
                                    Collection is sold out
                                </p>
                                <button
                                    className='btn btn-outline-primary btn-md active'
                                    onClick={() =>
                                        window.open(
                                            'https://flipix.io/collection/EVBDG-6e4310',
                                            '_blank'
                                        )
                                    }
                                >
                                    🛒 Trade now on FLIPiX
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
